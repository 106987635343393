import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";

mixpanel.init(
  window.location.hostname === "localhost"
    ? "75d71eeb2f3dffaab5994403f74f28f8"
    : "65f7ae75afb9271ed2803f48f178b88a",
  {
    debug: window.location.hostname === "localhost",
    track_pageview: false,
    persistence: "localStorage",
    ignore_dnt: true,
  }
);

export function initAnalytics() {
  console.log("Init PH");
  posthog.init("phc_SchO0fA6DPQmXOmOsycPycNZ4Dbw8pDLk87iObcz3d5", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    autocapture: false,
    capture_pageview: false,
    enable_heatmaps: false,
  });
}

export function trackPageView() {
  posthog.capture("$pageview");
  mixpanel.track_pageview();
}

export function identifyUser(
  userId: string,
  email: string | null,
  displayName: string
) {
  posthog.identify(userId, { email, name: displayName });
  mixpanel.identify(userId);
  mixpanel.people.set({
    $email: email,
    $name: displayName,
  });
}

export function trackClientEvent(
  event: string,
  properties: Record<string, any>
) {
  console.log("trackClientEvent", event, properties);
  posthog.capture(event, properties);
  mixpanel.track(event, properties);
}
